import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VideoBg from "vue-videobg";
import VueCarousel from "vue-carousel";

import "@/assets/css/styles.css";
import "@/assets/css/background-animated.css";

Vue.config.productionTip = false;
Vue.component("video-bg", VideoBg);
Vue.use(VueCarousel);

new Vue({
	router,
	store,
	vuetify,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
