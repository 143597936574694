<template>
	<div>
		<video-bg
			:sources="['/assets/videos/depa1.mp4']"
			img="/assets/videos/depa1.mp4"
			class="mt-n16"
			:style="`max-height: ${heightVideo}; height: ${heightVideo}`">
			<v-responsive
				id="home"
				:height="`${heightVideo}`"
				class="animated-gradient-purple-inmobi d-flex align-end">
				<v-container>
					<v-row class="d-flex flex-column align-end">
						<v-col md="12" class="mb-12 text-right">
							<h1
								class="lato-black display-4 white--text font-weight-black text-shadow">
								INM<span class="deep-purple--text text--darken-4">O</span>BI
							</h1>
							<!-- <h1 class="red text-right">
								<v-img
									lazy-src="/assets/images/inmobi-logo-w.svg"
									max-height="90"
									max-width="360"
									class="blue float--right"
									src="/assets/images/inmobi-logo-w.svg"></v-img>
							</h1> -->

							<p class="text-h6 white--text font-weight-regular text-uppercase">
								Inmuebles en recuperación y compraventa
							</p>
						</v-col>

						<v-col md="9" class="mb-12 align-self-start parallax--gradiente">
							<v-row>
								<v-col class="d-flex align-center white--text">
									<span class="text-h5 font-weight-medium text-shadow">1</span>
									<span class="text-h2 font-weight-thin">/</span>
									<span
										class="text-h6 font-weight-medium text-shadow text-uppercase"
										>Experiencia</span
									>
								</v-col>
								<v-col class="d-flex align-center white--text">
									<span class="text-h5 font-weight-medium text-shadow">2</span>
									<span class="text-h2 font-weight-thin">/</span>
									<span
										class="text-h6 font-weight-medium text-shadow text-uppercase"
										>Transparencia</span
									>
								</v-col>
								<v-col class="d-flex align-center white--text">
									<span class="text-h5 font-weight-medium text-shadow">3</span>
									<span class="text-h2 font-weight-thin">/</span>
									<span
										class="text-h6 font-weight-medium text-shadow text-uppercase"
										>Asesoría</span
									>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-responsive>
		</video-bg>

		<v-container id="quienes-somos">
			<v-row class="my-16 py-16">
				<v-col md="6" class="d-flex flex-column justify-center">
					<h3
						class="display-3 deep-purple--text text--darken-4 mb-4 text-center">
						¿Quiénes Somos?
					</h3>
					<p class="lead font-weight-light grey--text text--darken-2">
						En <strong class="font-weight-bold">InMobi</strong> , nos dedicamos
						a ofrecer una forma innovadora para remates inmobiliarios,
						conectando compradores y vendedores de propiedades de manera
						eficiente y transparente. Nuestra misión es simplificar el proceso
						de adquisición de propiedades en remate, brindando herramientas y
						recursos que faciliten la toma de decisiones inteligentes.
					</p>
					<p class="lead font-weight-light grey--text text--darken-2">
						Durante una década de servicio, hemos facilitado miles de
						transacciones exitosas, ayudando a compradores y vendedores a
						encontrar las mejores oportunidades en el mercado inmobiliario.
						Nuestro compromiso con la transparencia, la integridad y la
						satisfacción del cliente ha sido la piedra angular de nuestro éxito.
					</p>
				</v-col>
				<v-col md="6">
					<v-img
						lazy-src="/assets/images/remate.jpg"
						aspect-ratio="1.7"
						src="/assets/images/remate.jpg"></v-img>
				</v-col>
			</v-row>
		</v-container>

		<v-responsive
			class="animated-gradient-blue-grey d-flex align-end"
			id="porque">
			<v-container>
				<v-row class="my-12 py-12">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3
							class="display-3 blue-grey--text text--lighten-5 mb-4 text-center mt-1">
							¿Porqué elegirnos?
						</h3>
					</v-col>
					<v-col md="12" class="">
						<v-row class="d-flex justify-center">
							<v-col md="3" class="text-center">
								<v-icon size="128" class="block blue-grey--text text--lighten-3"
									>mdi-star-shooting</v-icon
								>
								<h3
									class="text-h4 font-weight-regular text-uppercase blue-grey--text text--lighten-5">
									Experiencia
								</h3>
								<p
									class="blue-grey--text text--lighten-2 lead font-weight-light">
									Contamos con un equipo experimentado en el mercado
									inmobiliario y en la organización de remates.
								</p>
							</v-col>
							<v-col md="3" class="text-center">
								<v-icon size="128" class="block blue-grey--text text--lighten-3"
									>mdi-magnify-scan</v-icon
								>
								<h3
									class="text-h4 font-weight-regular text-uppercase blue-grey--text text--lighten-5">
									Transparencia
								</h3>
								<p
									class="blue-grey--text text--lighten-2 lead font-weight-light">
									Nos comprometemos a brindar información clara y precisa sobre
									cada propiedad en remate.
								</p>
							</v-col>
							<v-col md="3" class="text-center">
								<v-icon size="128" class="block blue-grey--text text--lighten-3"
									>mdi-account-group-outline</v-icon
								>
								<h3
									class="text-h4 font-weight-regular text-uppercase blue-grey--text text--lighten-5">
									Asesoría
								</h3>
								<p
									class="blue-grey--text text--lighten-2 lead font-weight-light">
									Ofrecemos asesoramiento personalizado para ayudarte a
									encontrar la propiedad ideal.
								</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-responsive class="mt-16 pt-16 elevation-0">
			<MapBoxLayout></MapBoxLayout>
		</v-responsive>

		<v-responsive
			class="d-flex align-end"
			style="background-color: #2e4c6b"
			id="contacto">
			<v-container>
				<v-row class="my-12 py-12">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3
							class="display-3 blue-grey--text text--lighten-5 mb-4 text-center mt-1">
							Contactanos
						</h3>
					</v-col>
					<v-col md="12" class="">
						<v-row class="d-flex justify-center">
							<v-col md="4" class="text-center">
								<v-icon size="96" class="blue-grey--text text--lighten-3"
									>mdi-at</v-icon
								>
								<h3 class="text-h5">
									<a
										href="mailto:ventas@inmobirecuperacion.com"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>ventas@inmobirecuperacion.com</a
									>
								</h3>
							</v-col>
							<v-col md="4" class="text-center">
								<v-icon size="96" class="blue-grey--text text--lighten-3"
									>mdi-phone-settings</v-icon
								>
								<h3 class="text-h5">
									<a
										href="tel:015544443333"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>01 55 4444 3333</a
									>
								</h3>
							</v-col>
							<v-col md="4" class="text-center">
								<v-icon size="96" class="blue-grey--text text--lighten-3"
									>mdi-at</v-icon
								>
								<h3 class="text-h5">
									<a
										href="mailto:atencion@inmobirecuperacion.com"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>atencion@inmobirecuperacion.com</a
									>
								</h3>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-responsive
			class="d-flex align-end"
			style="background: url('/assets/images/back-clientes.png')"
			id="clientes">
			<v-container
				style="
					background: url('/assets/images/city4.png') no-repeat bottom;
					background-size: contain;
				">
				<v-row class="my-16 py-16">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3
							class="display-3 blue-grey--text text--darken-4 mb-4 text-center">
							Las opiniones de nuestros clientes
						</h3>
					</v-col>
					<v-col>
						<carousel
							cycle
							:autoplay="true"
							:perPageCustom="[
								[320, 1],
								[480, 2],
								[768, 3],
								//[1199, 4],
							]"
							:centerMode="true"
							:loop="true"
							:speed="800"
							:autoplayTimeout="3000"
							:interval="6000">
							<slide v-for="(comment, i) in comments" :key="i" :data-index="i">
								<div class="ma-4 pa-4 text-center">
									<v-avatar color="white" size="275" class="elevation-5">
										<img
											:src="comment.photo"
											:lazy-src="comment.photo"
											class="mx-auto mt-2" />
									</v-avatar>
									<h4 class="mt-4 text-h4">{{ comment.name }}</h4>
									<small class="text-uppercase blue-grey--text text--darken-2">
										{{ comment.job }}
									</small>
									<p class="blue-grey--text text--darken-3">
										{{ comment.comment }}
									</p>
								</div>
							</slide>
						</carousel>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-footer color="blue-grey darken-4" class="">
			<v-container>
				<v-row>
					<v-col class="text-center">
						<small
							class="blue-grey--text text--lighten-3 text--uppercase font-weight-light">
							Derechos reservados 2024 <br /><strong>INMOBI</strong>, Inmuebles
							en recuperación y compraventa
						</small>
					</v-col>
					<!-- <v-col>2</v-col>
					<v-col>3</v-col> -->
				</v-row>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
import MapBoxLayout from "@/components/MapBox.vue";
import jsonComments from "@/assets/data/comments.json";

export default {
	name: "HelloWorld",

	components: {
		MapBoxLayout,
	},

	data: () => ({
		heightVideo: "96vh",
		changeMenu: false,
		comments: jsonComments,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		nameRoute: function () {
			return this.$route.name;
		},
		breakpoint: function () {
			return this.$vuetify.breakpoint;
		},
	},

	mounted() {},

	methods: {},
};
</script>

<style>
.VueCarousel-dot {
	background-color: #b0bec5 !important;
}
.VueCarousel-dot--active {
	background-color: #455a64 !important;
}
</style>
