<template>
	<section class="">
		<v-row dark class="elevation-0">
			<v-col md="12" class="text-center">
				<h3 class="display-3 deep-purple--text text--darken-4 mb-4 text-center">
					Propiedades disponibles
				</h3>
			</v-col>

			<v-col cols md="12" class="">
				<MglMap
					:accessToken="accessToken"
					:mapStyle.sync="mapStyle"
					:zoom="zoom"
					:center="center"
					:attributionControl="true"
					@load="onMapLoaded">
					<MglNavigationControl position="top-left" />
					<MglGeolocateControl position="top-left" />
					<!-- <MglFullscreenControl position="top-left" /> -->

					<MglMarker
						v-for="(state, i) in states"
						:key="`realstate-${i}`"
						:coordinates="[
							state.lon ? state.lon : i,
							state.lat ? state.lat : i,
						]"
						color="#F50057">
						<MglPopup anchor="bottom">
							<v-row>
								<v-col cols md="12" class="mt-3 text-center">
									<h4 class="text-h6 font-weight-regular text-center mt-2 mb-4">
										Propiedad: <strong>{{ state.title }}</strong>
									</h4>
									<div class="card-body mx-4" v-html="state.description"></div>

									<v-simple-table class="mx-auto mb-4" :style="`width:90%`">
										<template v-slot:default>
											<tbody>
												<tr>
													<td class="blue-grey darken-4">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-home-city
																</v-icon>
															</template>
															<strong>Tipo de Construcción</strong>
														</v-tooltip>
													</td>
													<td
														colspan="3"
														class="blue-grey darken-1 font-weight-black text-uppercase white--text">
														{{
															state.typeBuilding === 1 ? "Departamento" : "Casa"
														}}
													</td>
												</tr>
												<tr>
													<td width="25%" class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-bed
																</v-icon>
															</template>
															<strong>Habitaciones</strong>
														</v-tooltip>
													</td>
													<td
														width="25%"
														class="card-body text-uppercase font-weight-black">
														{{ state.room }}
													</td>
													<td width="25%" class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-toilet
																</v-icon>
															</template>
															<strong>Baños</strong>
														</v-tooltip>
													</td>
													<td
														width="25%"
														class="card-body text-uppercase font-weight-black">
														{{ state.bath }}
													</td>
												</tr>

												<tr>
													<td class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-car-multiple
																</v-icon>
															</template>
															<strong>Cajones de estacionamiento</strong>
														</v-tooltip>
													</td>
													<td
														class="card-body text-uppercase font-weight-black">
														{{ state.parking }}
													</td>

													<td class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-human-scooter
																</v-icon>
															</template>
															<strong>Amenidades</strong>
														</v-tooltip>
													</td>
													<td class="card-body text-uppercase">
														<v-icon>
															{{
																state.amenity
																	? "mdi-check-bold"
																	: "mdi-circle-off-outline"
															}}
														</v-icon>
													</td>
												</tr>
												<tr>
													<td class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-paw
																</v-icon>
															</template>
															<strong>Pet Friendly</strong>
														</v-tooltip>
													</td>
													<td class="card-body text-uppercase">
														<v-icon>
															{{
																state.petFriendly
																	? "mdi-check-bold"
																	: "mdi-circle-off-outline"
															}}
														</v-icon>
													</td>
													<td class="blue-grey darken-3">
														<v-tooltip right color="black">
															<template v-slot:activator="{ on, attrs }">
																<v-icon dark v-bind="attrs" v-on="on">
																	mdi-plane-train
																</v-icon>
															</template>
															<strong>Transporte cercano</strong>
														</v-tooltip>
													</td>
													<td class="card-body text-uppercase">
														<v-icon>
															{{
																state.transport
																	? "mdi-check-bold"
																	: "mdi-circle-off-outline"
															}}
														</v-icon>
													</td>
												</tr>
												<tr>
													<td colspan="4">
														<v-img
															:lazy-src="`${state.image}`"
															:src="`${state.image}`"
															max-width="280"
															aspect-ratio="1.75"
															class="mx-auto my-6">
														</v-img>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
							</v-row>
						</MglPopup>
					</MglMarker>
				</MglMap>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import jsonState from "@/assets/data/state.json";

import Mapbox from "mapbox-gl";
import {
	MglMap,
	MglAttributionControl,
	MglNavigationControl,
	MglGeolocateControl,
	MglFullscreenControl,
	MglScaleControl,
	MglPopup,
	MglMarker,
} from "vue-mapbox";

export default {
	name: "MapBoxLayout",

	components: {
		MglMap,
		MglAttributionControl,
		MglNavigationControl,
		MglGeolocateControl,
		MglFullscreenControl,
		MglScaleControl,
		MglPopup,
		MglMarker,
	},

	data: () => ({
		states: jsonState,
		dialog: false,
		dialogComunidades: false,

		accessToken: process.env.VUE_APP_MAPBOX,
		mapStyle: "mapbox://styles/mapbox/streets-v12",
		//mapStyle: "mapbox://styles/mapbox/outdoors-v12",
		//mapStyle: "mapbox://styles/mapbox/basic-v12",
		//center: [-65.992042, 45.284788],
		center: [-101.9345574, 23.9250822],
		zoom: 4.65,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		document.title = `${this.meta.app_name}`;
	},

	created() {
		this.mapbox = Mapbox;
	},

	methods: {
		onMapLoaded(event) {
			this.map = event.map;
			this.$store.map = event.map;
		},
	},
};
</script>

<style>
.html-block p {
	font-weight: 500 !important;
}
.mgl-map-wrapper,
.mapboxgl-control-container {
	width: 100%;
	height: 76vh;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
	background-color: transparent;
}
</style>
